import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CanAuthenticationGuard} from '@core/guard/can-authentication.guard';

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: 'batchpayments',
    loadChildren: () => import('./batch-payment/batch-payment.module').then(m => m.BatchPaymentModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'beneficiaries',
    loadChildren: () => import('./beneficiary/beneficiary.module').then(m => m.BeneficiaryModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'beneficiarygroups',
    loadChildren: () => import('./beneficiary-group/beneficiary-group.module').then(m => m.BeneficiaryGroupModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'countries',
    loadChildren: () => import('./country/country.module').then(m => m.CountryModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'funding',
    loadChildren: () => import('./funding/funding.module').then(m => m.FundingModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/fast-dashboard.module').then(m => m.FastDashboardModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'donations',
    loadChildren: () => import('./donation/donation.module').then(m => m.DonationModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'fieldaffiliates',
    loadChildren: () => import('./organisation/field-affiliate/field-affiliate.module').then(m => m.FieldAffiliateModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'localpartners',
    loadChildren: () => import('./local-partner/local-partner.module').then(m => m.LocalPartnerModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'organisations',
    loadChildren: () => import('./organisation/organisation.module').then(m => m.OrganisationModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'payments',
    loadChildren: () => import('./donation/payment.module').then(m => m.PaymentModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'projects',
    loadChildren: () => import('./organisation/project/project-routing.module').then(m => m.ProjectRoutingModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'questionnaire/forms',
    loadChildren: () => import('./questionnaire-form/questionnaire-form.module').then(m => m.QuestionnaireFormModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'questionnaires',
    loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'questions',
    loadChildren: () => import('./question/question.module').then(m => m.QuestionModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'questioncategories',
    loadChildren: () => import('./question-category/question-category.module').then(m => m.QuestionCategoryModule),
    canActivate: [CanAuthenticationGuard]
  },
  {
    path: 'quests',
    loadChildren: () => import('./quest/quest.module').then(m => m.QuestModule),
    canActivate: [CanAuthenticationGuard]
  },
  {path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule), canActivate: [CanAuthenticationGuard]},
  {path: 'surveys', loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule), canActivate: [CanAuthenticationGuard]},
  {path: 'tasks', loadChildren: () => import('./task/task.module').then(m => m.TaskModule), canActivate: [CanAuthenticationGuard]},
  {path: 'users', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [CanAuthenticationGuard]},
  {
    path: 'workflow',
    loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule),
    canActivate: [CanAuthenticationGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
